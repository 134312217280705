import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 3.000000)">
        <path
          d="m64.512 64.438h-4.997l-11.452-12.891-2.8777 3.0721v9.8191h-3.8499v-28.952h3.8499v15.108l14.058-15.108h4.6665l-12.93 13.611z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 60, 8
                  L 21, 30
                  L 21, 75
                  L 60, 98
                  L 99, 76
                  L 99, 31 z"
      />
    </g>
  </svg>
);

export default IconLoader;
