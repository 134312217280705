import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(-1.000000, 3.000000)" aria-label="K">
        <path
          d="m64.512 64.438h-4.997l-11.452-12.891-2.8777 3.0721v9.8191h-3.8499v-28.952h3.8499v15.108l14.058-15.108h4.6665l-12.93 13.611z" fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="10 27 10 72 49 95 88 72 88 28 49 5"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
