import React from 'react';

const IconIssuu = () => (
    <svg 
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-issuu">
        <path d="M24 5H6.625C5.728 5 5 5.728 5 6.625V24c0 10.493 8.507 19 19 19s19-8.507 19-19S34.493 5 24 5zm0 32c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"></path>
    </svg>
);

export default IconIssuu;